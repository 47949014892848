@import "reset.scss";
html, body {
  font-size: 16px;
  font-family: "Arial", sans-serif;
}

.login-container {
  //padding-top: 80px;
  margin: 40px auto 20px;
  width: 450px;
  border: 2px solid #e4e4e4;
  box-sizing: border-box;
  padding: 15px;
  &::before {
    content: "Efaktura Stat Login";
    display: block;
    margin: 0 auto 20px;
    background: url("../img/logo.svg") no-repeat 15px 50%;
    background-size: 30px;
    line-height: 30px;
    background-color: #e4e4e4;
    margin: -15px -15px 20px;
    padding: 15px;
    padding-left: 57px;
  }
  form {
    input, button {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
    input {
      height: 30px;
      margin-bottom: 12px;
      padding: 0 12px;
    }
  }
}
header {
  display: flex;
  background: #e4e4e4;
  padding: 8px 15px;
  align-items: center;
  h1 {
    flex-grow: 1;
  }
  button {
    flex-shrink: 0;
  }
}
.content {
  //background: yellow;
  padding: 8px 15px;
  h2 {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 20px;
  }
}
.data-table {
  width: 100%;
  max-width: 700px;
  th {
    font-weight: bold;
  }
  th, td {
    padding: 2px 0;
  }
}
